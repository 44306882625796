import './TeamCard.scss'
import { RiTwitterXLine } from "react-icons/ri"

interface TeamCardProps {
	title: string
	text: string
	twitterSrc: string
}

const TeamCard = ({ title, text, twitterSrc }: TeamCardProps) => {

	return (
		<div className="team-info-card">
			<div className="team-info-card-inner">
				<div className="team-info-card-title">
					{title}
				</div>
				<div className="team-info-card-text">
					<p>
						{text}
					</p>
					<a href={twitterSrc} target='_blank'>
						<RiTwitterXLine />
					</a>
				</div>
			</div>
		</div>
	)
}

export default TeamCard