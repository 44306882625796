import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import './Articles.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import DOMPurify from 'dompurify'
import { API_BASE_URL } from '../../../../global/constants'
import { getPosts } from '../../../../api/postApi'
import { removeHtmlTags } from '../../../../global/functions'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import H2box from '../../../components/H2box/H2box'
import ImageWithLoader from '../../../components/ImageWithLoader/ImageWithLoader'
import Skeleton from 'react-loading-skeleton'


interface Post {
	id: number;
	title: string;
	slug: string;
	category_name: string;
	publication_date: string;
	image_path: string;
	text: string;
}

const Articles = () => {
	const [posts, setPosts] = useState<Post[]>([])

	useEffect(() => {
		fetchPosts()
	}, [])

	const fetchPosts = async () => {
		try {
			const { data } = await getPosts(1, 10)
			setPosts(data.posts)
		} catch (error) {
			console.error('Error fetching posts:', error)
		}
	}

	return (
		<section className='articles'>
			<div className='container'>
				<H2box h2='Latest Articles' subtitle='Catch up on our latest thoughts and updates' margin='sm' />
				<div className="articles-wrapper">
					<Swiper
						spaceBetween={24}
						slidesPerView={4}
						navigation={{
							nextEl: '.swiper-next',
							prevEl: '.swiper-prev'
						}}
						modules={[Navigation]}
						className='articles-swiper'
						breakpoints={{
							320: {
								slidesPerView: 1,
							},

							992: {
								slidesPerView: 3,
							},
							1366: {
								slidesPerView: 4,
							}
						}}
					>
						{posts.map((post) => (
							<SwiperSlide key={post.id}>
								<Link to={`/articles/${post.category_name}/${post.slug}`} className='articles-slide'>
									<div className='articles-slide-img'>
										<ImageWithLoader src={`${API_BASE_URL}${post.image_path}`} alt='' spinnerSize="md" />
									</div>
									<div className="articles-slide-box">

										<div className='articles-slide-info'>
											<div className='category'>
												{post.category_name || <Skeleton />}
											</div>
											<div className='articles-slide-date'>
												{new Date(post.publication_date).toLocaleDateString() || <Skeleton />}
											</div>
										</div>
										<div className='articles-slide-inner'>
											<div className='articles-slide-text'>
												<h3>{post.title || <Skeleton />}</h3>
												<p>{DOMPurify.sanitize(removeHtmlTags(post.text)) || <Skeleton />}</p>
											</div>
										</div>
										<div className="articles-slide-hidden">
											<span>View article</span>
										</div>
									</div>
								</Link>
							</SwiperSlide>
						))}
					</Swiper>
					<div className='swiper-buttons'>
						<div className='swiper-prev'>
							<FaArrowLeft />
						</div>
						<div className='swiper-next'>
							<FaArrowRight />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Articles