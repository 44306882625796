import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'
import { PaginationProps } from '../types'

const Pagination = ({ currentPage, total, onPageChange }: PaginationProps) => (
    <div className="pagination">
        <button 
            className="pagination-button" 
            onClick={() => onPageChange('prev')} 
            disabled={currentPage === 0}
        >
            <FaLongArrowAltLeft />
        </button>
        <button 
            className="pagination-button" 
            onClick={() => onPageChange('next')} 
            disabled={(currentPage + 1) * 4 >= total}
        >
            <FaLongArrowAltRight />
        </button>
    </div>
)

export default Pagination
