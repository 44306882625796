import { useState } from 'react'
import Spinner from '../spinners/Spinner'
import './ImageWithLoader.scss'

interface ImageWithLoaderProps {
	src: string
	alt?: string
	className?: string
	spinnerSize: "sm" | "md" | "lg"
}

const ImageWithLoader = ({ src, alt, className="", spinnerSize }: ImageWithLoaderProps) => {
	const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)

	const handleLoad = () => {
		setIsImageLoaded(true)
	}

	return (
		<div className={`image-wrapper ${className}`}>
			{!isImageLoaded && <Spinner size={spinnerSize} />}
			<img
				src={src}
				alt={alt}
				onLoad={handleLoad}
				className={isImageLoaded ? 'img-showed' : ''}
			/>
		</div>
	)
}

export default ImageWithLoader
