// Sections
import Hero from "../../components/sections/Hero/Hero"
import ArticlesList from "../../sections/Articles/ArticlesList"

const Articles = () => {
	return (
		<div className="sections">
			<Hero type="center" h1="Articles"
				subtitle="Catch up on our latest thoughts and updates"
			/>
			<ArticlesList />
		</div>
	)
}

export default Articles