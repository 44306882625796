import Heading from "../../../components/Heading/Heading"

interface HeroProps {
	type: "" | "center"
	h1: string
	subtitle: string
}

const Hero = ({type ,h1,subtitle}: HeroProps) => {
  return (
	<section className="hero">
		<div className="container">
			<Heading type={type} h1={h1} subtitle={subtitle} />
		</div>
	</section>
)
}

export default Hero