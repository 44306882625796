import './AppCard.scss'
import ImageWithLoader from '../../ImageWithLoader/ImageWithLoader'
import LinkRouter from '../../links/LinkRouter/LinkRouter'

interface AppCardProps {
	type: '' | 'disabled'
	src: string
	name: string
	text: string
	linkSrc: string
}

const AppCard = ({ type, src, name, text, linkSrc }: AppCardProps) => {
	return (
		<div className={`app-card ${type}`}>
			<div className="app-card-img">
				{type !== 'disabled' && (
					<ImageWithLoader src={src} spinnerSize="md" />
				)}
			</div>
			<div className="app-card-inner">
				<div className="app-card-name">{name}</div>
				<p>{text}</p>
				<LinkRouter type="green" src={linkSrc} targetBlank text="Go to App" />
			</div>
		</div>
	)
}

export default AppCard
