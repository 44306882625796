import './ImageTextWrapper.scss'
import ImageText from '../../../components/sections/ImageText/ImageText'
import c1 from '../../../../assets/img/sections/image-text/c1.png'
import c2 from '../../../../assets/img/sections/image-text/c2.png'
import c3 from '../../../../assets/img/sections/image-text/c3.png'

const ImageTextWrapper = () => {
	return (
		<div className="image-text-wrapper">
			<ImageText
				direction=""
				h2="Who we are"
				text="In our team, everyone has the opportunity to express their ideas,  contribute and grow professionally. We support openness, tolerance and  mutual respect, creating an atmosphere in which each team member feels  respected and an important link in the common cause."
				linkType="gradient"
				linkSrc="/about"
				linkText="Read more"
				imgAlt="Three cats laughs"
				imgSrc={c1}
				spinnerSize="lg"
			/>
			<ImageText
				direction="reverse"
				h2="Creating Apps"
				text="We are excited to announce our plans to develop apps tailored to the needs of Stargaze users. Our team is dedicated to creating intuitive and engaging solutions that enhance your experience on the platform. Whether you’re looking for innovative tools for work or fun, we aim to deliver essential apps that elevate your Stargaze journey. Stay tuned for updates on our upcoming projects!"
				linkType="gradient"
				linkSrc="/apps"
				linkText="View Apps"
				imgAlt="Cat develops something"
				imgSrc={c2}
				spinnerSize="lg"
			/>
			<ImageText
				direction=""
				h2="Creating NFTs"
				text="We are excited to share our commitment to creating unique pixel NFTs that resonate with the Stargaze community. Our team is dedicated to designing captivating and one-of-a-kind pixel art pieces that not only stand out but also tell a story. Whether you're a collector or a creator, our aim is to deliver exceptional NFTs that enhance your experience on the platform. Stay tuned for more updates on our upcoming pixel NFT projects!"
				linkType="gradient"
				linkSrc="/collections"
				linkText="View Collections"
				imgAlt="Green brain on abstract background"
				imgSrc={c3}
				spinnerSize="lg"
			/>
		</div>
	)
}

export default ImageTextWrapper
