import './PartnershipsCards.scss'
import PartnershipsCard from '../../../components/Cards/PartnershipsCard/PartnershipsCard'
import img1 from '../../../../assets/img/sections/partnerships/shitmos.png'
import img2 from '../../../../assets/img/svg/dao.svg'
import img3 from '../../../../assets/img/sections/partnerships/smoker.jpg'

const PartnershipsCards = () => {
  return (
	<section className='partnerships-cards'>
		<div className="container">
			<div className="partnerships-cards-wrapper">
				<PartnershipsCard src={img1} discordLink='https://discord.com/invite/UHxk7hdecU' xLink='https://x.com/ShitonCosmos' h4='Shitmos' text='Shitmos Economic Zone - we are the one of first collections who joined  SEZ. 20% of our royalties  goes to SEZ treasury for buybacks of Shitmos - a real community moving memecoin in whole Cosmos Community is very  strong and grows every day.' />
				<PartnershipsCard src={img2} discordLink='https://discord.com/invite/KaRxYPNnuw' xLink='' h4='DAO DAO' text='Join Crypto Matcha DAO to improve our strength! Stake Hamsters and vote in governance. Earn plstaking points with multiplier! Revenues from treasury coming in future.' />
				<PartnershipsCard src={img3} discordLink='https://discord.com/invite/uUcGMxQ5zB' xLink='' h4='Smoker Club' text='The Smokers Club - we have many traits in both collections which gives you membership in Smokers Club - place where you can join rumbles and other games and win nice NFTs. Also you can soft-stake smokers NFT on plstaking to earn SMKR token and join raffles there.' />
			</div>
		</div>
	</section>
)
}

export default PartnershipsCards