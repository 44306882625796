import ImageWithLoader from "../../ImageWithLoader/ImageWithLoader"
import LinkRouter from "../../links/LinkRouter/LinkRouter"
import './ImageText.scss'

interface ImageTextProps {
	direction: string
	h2: string
	text: string
	linkType: 'green' | 'gradient'
	linkSrc: string
	linkText: string
	imgSrc: string
	imgAlt: string
	spinnerSize: 'sm' | 'md' | 'lg'
}

const ImageText = ({ direction, h2, text, linkType, linkSrc, linkText, imgAlt, imgSrc, spinnerSize }: ImageTextProps) => {
	return (
		<section className={`image-text ${direction}`}>
			<div className="container">
				<div className="image-text-inner">
					<div className="image-text-info">
						<h2>{h2}</h2>
						<p>{text}</p>
						<LinkRouter type={linkType} src={linkSrc} text={linkText} />
					</div>
					<div className="image-text-img">
						<ImageWithLoader src={imgSrc} alt={imgAlt} spinnerSize={spinnerSize} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default ImageText
