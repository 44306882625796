import './WalletStats.scss'
import { useState, useEffect } from 'react'
import { useChain } from '@cosmos-kit/react'
import { GET_WALLET_STATS } from '../../../../global/queries'
import { WalletStats as WalletStatsType } from '../types'
import client from '../../../../global/ApolloClient'
import WalletInfo from '../WalletInfo/WalletInfo'
import TokenLists from '../TokenLists/TokenLists'

const WalletStats = () => {
	const { address } = useChain('stargaze')
	const [walletStats, setWalletStats] = useState<WalletStatsType | null>(null)

	useEffect(() => {
		const fetchWalletStats = async () => {
			if (!address) return

			try {
				const response = await client.query({
					query: GET_WALLET_STATS,
					variables: { address },
				})

				if (response.data.wallet.stats !== walletStats) {
					setWalletStats(response.data.wallet.stats)
				}
			} catch (error) {
				console.error('Error fetching wallet stats:', error)
			}
		}

		fetchWalletStats()
	}, [address])

	return (
		<section className="wallet-stats">
			<div className="container">
				<h2>Wallet stats</h2>
				<WalletInfo walletStats={walletStats} />
				<TokenLists address={address as string} />
			</div>
		</section>
	)
}

export default WalletStats
