import './ImageTextSingle.scss'
import ImageWithLoader from '../../../components/ImageWithLoader/ImageWithLoader'
import LinkRouter from '../../../components/links/LinkRouter/LinkRouter'
import H2box from '../../../components/H2box/H2box'
import img from '../../../../assets/img/sections/image-text/it1.png'

const ImageTextSingle = () => {
	return (
		<section className='image-text-single'>
			<div className="container">
				<div className="image-text-single-wrapper">
					<H2box h2='Grow With Us' subtitle='Together to New Heights' margin='sm' />
				</div>
				<div className="image-text-single-inner">
					<div className="image-text-single-info">
						<p>
							We are excited to share our commitment to creating unique pixel NFTs that resonate with the Stargaze community. Our team is dedicated to designing 	captivating and one-of-a-kind pixel art pieces that not only stand out but also tell a story. Whether you're a collector or a creator, our aim is to deliver exceptional NFTs that enhance your experience on the platform. Stay tuned for more updates on our upcoming pixel NFT projects!
						</p>
						<div className="image-text-single-links">
							<LinkRouter type="gradient" src="https://stars.pampit.fun/token/CRMH-1" targetBlank={true} text="Pump It!" />
							<LinkRouter type="gradient" src="https://start.cooking/swap/osmo17myn8ppgfe7pfu6s2yuyf3vryus0vffx767ajvch8ltqxu20d63s4aez0u" targetBlank={true} text="Cook It!" />
						</div>
					</div>
					<div className="image-text-single-img">
						<ImageWithLoader src={img} alt="Green glass of macha" spinnerSize='lg' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default ImageTextSingle