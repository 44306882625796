import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DOMPurify from "dompurify"
import './ArticlesList.scss'
import { getPosts, getCategories } from "../../../api/postApi"
import { API_BASE_URL } from "../../../global/constants"
import Pagination from "../../components/Pagination/Pagination"
import ImageWithLoader from "../../components/ImageWithLoader/ImageWithLoader"
import { MdCategory, MdDateRange } from "react-icons/md"
import { FaLongArrowAltRight } from "react-icons/fa"
import { CiSearch } from "react-icons/ci"
import { FaEye } from "react-icons/fa"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const POSTS_PER_PAGE = 12

const ArticlesList = () => {
	const [posts, setPosts] = useState<any[]>([])
	const [categories, setCategories] = useState<any[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPosts, setTotalPosts] = useState(0)
	const [searchQuery, setSearchQuery] = useState("")
	const [selectedCategory, setSelectedCategory] = useState("")
	const [sortOrder, setSortOrder] = useState("date")
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		fetchCategories()
	}, [])

	useEffect(() => {
		fetchPosts()
	}, [currentPage, searchQuery, selectedCategory])

	const fetchPosts = async () => {
		setLoading(true)
		try {
			const { data } = await getPosts(
				currentPage,
				POSTS_PER_PAGE,
				searchQuery,
				selectedCategory
			)
			setPosts(data.posts)
			setTotalPosts(data.total)
		} catch (error) {
			console.error("Error fetching posts:", error)
		} finally {
			setLoading(false)
		}
	}

	const fetchCategories = async () => {
		try {
			const { data } = await getCategories()
			setCategories(data)
		} catch (error) {
			console.error("Error fetching categories:", error)
		}
	}

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage)
	}

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value)
		setCurrentPage(1)
	}

	const handleCategoryChange = (category: string) => {
		setSelectedCategory(category)
		setCurrentPage(1)
	}

	const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSortOrder(event.target.value)
	}

	const stripHTMLTags = (text: string) => {
		return text.replace(/<\/?[^>]+(>|$)/g, "")
	}

	const sanitizeAndStripTags = (text: string) => {
		const sanitizedText = DOMPurify.sanitize(text)
		return stripHTMLTags(sanitizedText)
	}

	const timeAgo = (publicationDate: string) => {
		const now = new Date()
		const published = new Date(publicationDate)
		const diffMs = now.getTime() - published.getTime()
		const diffHours = Math.floor(diffMs / (1000 * 60 * 60))
		const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24))

		if (diffDays >= 1) {
			if (diffDays === 1) {
				return "Yesterday"
			}
			return published.toLocaleDateString()
		}

		return `${diffHours} hours ago`
	}

	const totalPages = Math.ceil(totalPosts / POSTS_PER_PAGE)

	const startPostIndex = (currentPage - 1) * POSTS_PER_PAGE + 1
	const endPostIndex = Math.min(currentPage * POSTS_PER_PAGE, totalPosts)

	const sortedPosts = [...posts].sort((a, b) => {
		if (sortOrder === "date") {
			return new Date(b.publication_date).getTime() - new Date(a.publication_date).getTime()
		}
		if (sortOrder === "views") {
			return b.views - a.views
		}
		return 0
	})

	return (
		<section className="articles-list" id="articles-list">
			<div className="container">
				<div className="articles-list-wrapper">
					<div className="articles-filter">
						<div className="categories">
							<div
								className={`category ${selectedCategory === "" ? "active" : ""}`}
								onClick={() => handleCategoryChange("")}
							>
								All
							</div>
							{categories.map((category) => (
								<div
									key={category.id}
									className={`category ${selectedCategory === category.name ? "active" : ""}`}
									onClick={() => handleCategoryChange(category.name)}
								>
									{category.name}
								</div>
							))}
						</div>
						<div className="search">
							<input
								type="search"
								placeholder="Search articles"
								value={searchQuery}
								onChange={handleSearchChange}
								className="search-input"
							/>
							<div className="post-search-img">
								<CiSearch />
							</div>
						</div>
					</div>
					<div className="articles-items">
						<div className="total-items">
							<span>Showing {startPostIndex}-{endPostIndex} of {totalPosts} articles</span>
							<div className="sort">
								<label htmlFor="sort">Sort by:</label>
								<select id="sort" value={sortOrder} onChange={handleSortChange}>
									<option value="date">Date</option>
									<option value="views">Views</option>
								</select>
							</div>
						</div>
						<div className="articles-items-wrapper">
							{loading ? ( 
								Array.from({ length: POSTS_PER_PAGE }).map((_, index) => (
									<div className="articles-item" key={index}>
										<Skeleton height={200} baseColor='#222222' highlightColor='#33333350' />
										<div className="articles-content">
											<Skeleton count={3} baseColor='#222222' highlightColor='#33333350' />
										</div>
									</div>
								))
							) : (
								sortedPosts.map((post) => (
									<div className="articles-item" key={post.id}>
										<Link
											to={`/articles/${post.category_name}/${post.slug}`}
											className="articles-link"
										>
											{post.image_path && (
												<div className="articles-item-img">
													<ImageWithLoader src={`${API_BASE_URL}${post.image_path}`} spinnerSize="md" alt={post.title} />
													<div className="articles-item-views">
														<FaEye />
														{post.views}
													</div>
												</div>
											)}
											<div className="articles-content">
												<div className="articles-content-info">
													<div className="articles-category">
														<MdCategory />
														{post.category_name}
													</div>
													<div className="articles-publicated">
														<MdDateRange />
														{timeAgo(post.publication_date)}
													</div>
												</div>
												<h4 className="articles-title">{post.title}</h4>
												<div className="text-content" dangerouslySetInnerHTML={{ __html: sanitizeAndStripTags(post.text) }} />
											</div>
										</Link>
										<div className="articles-item-hidden">
											<span>View article <FaLongArrowAltRight /></span>
										</div>
									</div>
								))
							)}
						</div>
					</div>
					{totalPages > 1 && (
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</div>
		</section>
	)
}

export default ArticlesList
