import './Spinner.scss'

interface SpinnerProps {
	size: "sm" | "md" | "lg"
}

const Spinner = ({ size }: SpinnerProps) => {
	return (
		<div className={`spinner ${size}`}>
			<div className="spinner-circle"></div>
		</div>
	)
}

export default Spinner