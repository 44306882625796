import { useState, useEffect, useCallback } from 'react'
import { GET_TOKENS } from '../../../../global/queries'
import client from '../../../../global/ApolloClient'
import TokenList from '../TokenList/TokenList'
import { TokenData, TokensResponse } from '../types'
import DaoList from '../DaoList/DaoList'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

Chart.register(ArcElement, Tooltip, Legend)

interface TokenListsProps {
	address: string
}

const TokenLists = ({ address }: TokenListsProps) => {
	const [tokenData, setTokenData] = useState<{ tokens: TokenData[]; total: number }>({ tokens: [], total: 0 })
	const [secondTokenData, setSecondTokenData] = useState<{ tokens: TokenData[]; total: number }>({ tokens: [], total: 0 })
	const [daoTokenData, setDaoTokenData] = useState<{ tokens: TokenData[]; total: number }>({ tokens: [], total: 0 })
	const [page, setPage] = useState(0)
	const [secondPage, setSecondPage] = useState(0)
	const [filteredTokenCount, setFilteredTokenCount] = useState(0)
	const [loading, setLoading] = useState(true)

	const fetchTokenData = useCallback(
		(collectionAddr: string, page: number, setTokenData: React.Dispatch<React.SetStateAction<{ tokens: TokenData[]; total: number }>>) => {
			client.query<TokensResponse>({
				query: GET_TOKENS,
				variables: {
					ownerAddrOrName: address,
					collectionAddr,
					sortBy: 'RARITY_ASC',
					limit: 4,
					offset: page * 4,
				},
			})
				.then((response) => {
					setTokenData({
						tokens: response.data.tokens.tokens,
						total: response.data.tokens.pageInfo.total,
					})
					setLoading(false)
				})
				.catch((error) => {
					console.error('Error fetching token data:', error)
					setLoading(false)
				})
		},
		[address]
	)

	const fetchDaoTokenDataFromJson = useCallback(async () => {
		try {
			const response = await fetch('/json/tokens.json')
			if (!response.ok) throw new Error('Failed to load DAO tokens JSON')
			const data = await response.json()
			setDaoTokenData({ tokens: data.tokens, total: data.tokens.length })
		} catch (error) {
			console.error('Error fetching DAO tokens from JSON:', error)
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			await Promise.all([
				fetchTokenData('stars1k7lmdfx2eh5k3dt4jz3uuv4wl6s0tyft2twwjy0mgs3qxs3u3ynssv8dr2', page, setTokenData),
				fetchTokenData('stars1u478mmpm4mv33dkhvt6eryrru3uer6lr5pp8vgt7mhyvxjekx9js9y3kvl', secondPage, setSecondTokenData),
			])
			setLoading(false)
		}

		fetchData()
	}, [page, secondPage, fetchTokenData])

	useEffect(() => {
		fetchDaoTokenDataFromJson()
	}, [fetchDaoTokenDataFromJson])

	const handleTokenCountChange = (count: number) => {
		setFilteredTokenCount(count)
	}

	const chartData = {
		labels: ['Crypto Hamsters', 'After The halving', 'Staked in DAO'],
		datasets: [
			{
				label: 'Number of tokens',
				data: [
					tokenData.tokens.length,
					secondTokenData.tokens.length,
					filteredTokenCount,
				],
				backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(153, 102, 255, 0.6)'],
				borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)', 'rgba(153, 102, 255, 1)'],
				borderWidth: 1,
			},
		],
	}

	const hasData = tokenData.tokens.length > 0 || secondTokenData.tokens.length > 0 || filteredTokenCount > 0

	return (
		<div className="token-images-wrapper">
			<div className="token-images-inner">
				{loading ? (
					<p>Loading token data...</p>
				) : (
					<>
						<TokenList tokenData={tokenData} currentPage={page} setCurrentPage={setPage} />
						<TokenList tokenData={secondTokenData} currentPage={secondPage} setCurrentPage={setSecondPage} />
					</>
				)}
			</div>
			<div className="token-images-dao">
				<h2>Staked in DAO</h2>
				{loading ? (
					<p>Loading DAO tokens...</p>
				) : (
					<DaoList tokenData={daoTokenData} currentPage={page} setCurrentPage={setPage} address={address} onTokenCountChange={handleTokenCountChange} />
				)}
			</div>
			<div className="chart-container">
				<h2>Total NFTs count</h2>
				{hasData ? (
					<div className="pie-chart-wrapper">
						<div className="pie-chart">
							{loading ? (
								<p>Loading chart...</p> 
							) : (
								<Pie data={chartData} />
							)}
						</div>
						<div className="tokens-data">
							{loading ? (
								<p>Loading token counts...</p>
							) : (
								<ul>
									<li>Crypto Hamsters: {tokenData.tokens.length} NFT</li>
									<li>After The Halving: {secondTokenData.tokens.length} NFT</li>
									<li>Staked in DAO: {filteredTokenCount} NFT</li>
								</ul>
							)}
						</div>
					</div>
				) : (
					<p>No data available to display the chart.</p>
				)}
			</div>
		</div>
	)
}

export default TokenLists
