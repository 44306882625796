// Sections
import Hero from "../../sections/Home/Hero/Hero"
import Info from "../../sections/Home/Info/Info"
import ImageTextWrapper from "../../sections/Home/ImageTextWrapper/ImageTextWrapper"
import Collaborations from "../../sections/Home/Collaborations/Collaborations"
import ImageTextSingle from "../../sections/Home/Grow/ImageTextSingle"
import Cta from "../../sections/Home/Cta/Cta"
import Articles from "../../sections/Home/Articles/Articles"

const Home = () => {
	return (
		<div className="sections">
			<Hero />
			<Info />
			<ImageTextWrapper />
			<Collaborations />
			<ImageTextSingle />
			<Cta />
			<Articles />
		</div>
	)
}

export default Home