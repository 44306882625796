import './Cta.scss'
import ImageWithLoader from '../../../components/ImageWithLoader/ImageWithLoader'
import img from '../../../../assets/img/sections/cta/afth.png'
import { RxDiscordLogo } from "react-icons/rx"
import { RiTwitterXLine } from "react-icons/ri"

const Cta = () => {
	return (
		<section className='cta'>
			<div className="container">
				<div className="cta-wrapper">
					<div className="cta-img">
						<ImageWithLoader src={img} spinnerSize='lg' />
					</div>
					<div className="cta-info">
						<div className="cta-info-text">
							<h2>Let’s Create Something Unique Together</h2>
							<p>
								Collaborate with us to create something unique! We’re open to working with creative projects to elevate your ideas and deliver an exciting experience for your audience. Reach out to discuss how we can bring your vision to life!
							</p>
							<div className="cta-info-links">
								<a href="https://discord.gg/cmAd6dNC" target='_blank'>
									<RxDiscordLogo />
								</a>
								<a href="https://x.com/crypmatcha" target='_blank'>
									<RiTwitterXLine />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Cta