import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { wallets as keplr } from "@cosmos-kit/keplr"
import { wallets as leap } from "@cosmos-kit/leap" 
import { ToastContainer } from 'react-toastify'
import { sessionOptions } from './html/common/Header/functions'
import { walletConnectOptions } from './html/common/Header/functions'
import MainLayout from './html/templates/MainLayout'
import Home from './html/pages/Home/Home'
import Apps from './html/pages/Apps/Apps'
import About from './html/pages/About/About'
import Collections from './html/pages/Collections/Collections'
import Partnerships from './html/pages/Partnerships/Partnerships'
import Articles from './html/pages/Articles/Articles'
import ArticlesSingle from './html/templates/ArticlesSingle/ArticlesSingle'
import UserDashboard from './html/pages/UserDashboard/UserDashboard'
import Error404 from './html/errors/Error404/Error404'
import ScrollToTop from './html/components/scrollTop/ScrollTop'
import Login from './html/admin-area/auth/Login/Login'
import AdminDashboard from './html/admin-area/AdminDashboard/AdminDashboard'
import PrivateRoute from './routes/privateRoute'
import AdminRoute from './routes/AdminRoute'
import { ChainProvider } from "@cosmos-kit/react"
import { ThemeProvider } from "@interchain-ui/react"
import useChainData from './hooks/useChainData'

const App = () => {
    const { chains, assets } = useChainData(['stargaze'])

    if (chains.length === 0 || assets.length === 0) {
        return null
    }

    return (
        <ThemeProvider>
            <ChainProvider
                chains={chains}
                assetLists={assets}
                wallets={[...keplr, ...leap]}
                walletConnectOptions={walletConnectOptions}
                sessionOptions={sessionOptions}
				logLevel='NONE'
				>
                <Router>
                    <ToastContainer autoClose={1000} />
                    <ScrollToTop />
                    <Routes>
                        <Route path="/admin/login" element={<Login />} />
                        <Route path="/admin/dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
                        <Route path="*" element={<Error404 />} />
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<Home />} />
                            <Route path='/apps' element={<Apps />} />
                            <Route path='/about' element={<About />} />
                            <Route path='/collections' element={<Collections />} />
                            <Route path='/partnerships' element={<Partnerships />} />
                            <Route path='/articles' element={<Articles />} />
                            <Route path="/articles/:category/:slug" element={<ArticlesSingle />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/user-dashboard" element={<UserDashboard />} />
                            </Route>
                        </Route>
                    </Routes>
                </Router>
            </ChainProvider>
        </ThemeProvider>
    )
}

export default App
