import './Heading.scss'
import 'react-loading-skeleton/dist/skeleton.css'

interface HeadingProps {
	type: string | "center"
	h1: string
	subtitle: string
}

const Heading = ({ type, h1, subtitle }: HeadingProps) => {
	return (
		<div className={`heading ${type}`}>
			<h1>
				{h1}
			</h1>
			<div className="subtitle">
				{subtitle}
			</div>
		</div>
	)
}

export default Heading
