import Hero from "../../components/sections/Hero/Hero"
import CollectionItems from "../../sections/Collections/СollectionItems/CollectionItems"
import Collaborations from "../../sections/Home/Collaborations/Collaborations"
import Articles from "../../sections/Home/Articles/Articles"

const Collections = () => {
	return (
		<div className="sections">
			<Hero type="center" h1="Collections"
				subtitle="Latest NFT works"
			/>
			<CollectionItems />
			<Collaborations />
			<Articles />
		</div>
	)
}

export default Collections