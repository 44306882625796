import './Footer.scss'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/img/svg/logo.svg'
import ImageWithLoader from '../../components/ImageWithLoader/ImageWithLoader';
import { FaTelegramPlane } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { RxDiscordLogo } from "react-icons/rx"
import { RiTwitterXLine } from "react-icons/ri"
import { BsMedium } from "react-icons/bs";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-left">
						<Link className='footer-logo' to="/">
							<ImageWithLoader src={logo} spinnerSize='sm' />
						</Link>
						<p>
							Need assistance? You can quickly reach us via our Telegram channel or submit a request via email. We're here to help!
						</p>
						<div className="footer-contacts">
							<a href="https://t.me/monsb1" target='_blank'>
								<FaTelegramPlane />
							</a>	
							<a href="mailto:sumkadron@gmail.com">
								<IoMailSharp />
							</a>	
						</div>
						<nav className="footer-nav">
							<NavLink to="/apps">Apps</ NavLink>
							<NavLink to="/about">About</ NavLink>
							<NavLink to="/collections">Collections</ NavLink>
							<NavLink to="/partnerships">Partnerships</ NavLink>
							<NavLink to="/articles">Articles </NavLink>
						</nav>			
					</div>
					<div className="footer-right">
						<div className="footer-right-heading">
							<h4>
								Be part of our community
							</h4>
							<p>
								Engage with our community, stay up-to-date, and connect with others who share your interests. Follow us for fresh updates and exclusive content!
							</p>
						</div>
						<div className="footer-contacts fe">
							<a href="https://discord.gg/cmAd6dNC" target='_blank'>
								<RxDiscordLogo />
							</a>	
							<a href="https://x.com/crypmatcha" target='_blank'>
								<RiTwitterXLine />
							</a>
							<a href="https://medium.com/@cryptomatcha" target='_blank'>
								<BsMedium />
							</a>		
						</div>
						<p className='copyright'>Cryptomatcha 2024. Beta 1.0</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
