// Sections
import Hero from "../../components/sections/Hero/Hero"
import PartnershipsCards from "../../sections/Partnerships/PartnershipsCards/PartnershipsCards"
import Cta from "../../sections/Home/Cta/Cta"
import Articles from "../../sections/Home/Articles/Articles"

const Partnerships = () => {
	return (
		<div className="sections">
			<Hero type="center" h1="Partnerships"
				subtitle="Our partners"
			/>
			<PartnershipsCards />
			<Cta />
			<Articles />
		</div>
	)
}

export default Partnerships