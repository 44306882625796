import { ReactNode } from "react"
import './Goal.scss'

interface GoalProps {
	icon: ReactNode
	title: string
	description: string
}

const Goal = ({ icon, title, description }: GoalProps) => {
	return (
		<div className="goal">
			<div className="goal-ico">{icon}</div>
			<div className="goal-info">
				<div className="goal-title">{title}</div>
				<p>{description}</p>
			</div>
		</div>
	)
}

export default Goal