import './Collection.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import { useState, useEffect, useCallback } from 'react'
import { gql } from '@apollo/client'
import ImageWithLoader from '../../ImageWithLoader/ImageWithLoader'
import CollectionTheme from '../../Skeletons/CollectionTheme/CollectionTheme'
import client from '../../../../global/ApolloClient'
import Graph from './Graph'
import stars from '../../../../assets/img/svg/stars.svg'
import { FaFire } from "react-icons/fa"
import { FaParachuteBox } from "react-icons/fa6"

interface CollectionProps {
	collectionAddr: string
	type: '' | 'reverse'
}

interface CollectionData {
	name: string
	description: string
	floorPrice: number
	media: {
		visualAssets: {
			lg: {
				staticUrl: string
			}
		}
	}
	highestOffer: {
		offerPrice: {
			amount: number
		}
	}
	tokenCounts: {
		active: number
		airdropped: number
		burned: number
	}
	royaltyInfo: {
		sharePercent: number
	}
	contractUri: string
}

interface TokenData {
	media: {
		visualAssets: {
			sm: {
				staticUrl: string
			}
		}
	}
}

const getCollectionData = async (collectionAddr: string) => {
	return client.query<{ collection: CollectionData }>({
		query: gql`
      query CollectionData($address: String!) {
        collection(address: $address) {
          name
          description
          floorPrice
          media {
            visualAssets {
              lg {
                staticUrl
              }
            }
          }
          highestOffer {
            offerPrice {
              amount
            }
          }
          tokenCounts {
            active
            airdropped
            burned
          }
          royaltyInfo {
            sharePercent
          }
          contractUri
        }
      }
    `,
		variables: { address: collectionAddr },
	})
}

const getTokensData = async (collectionAddr: string) => {
	return client.query<{ tokens: { tokens: TokenData[] } }>({
		query: gql`
      query Query($collectionAddr: String) {
        tokens(collectionAddr: $collectionAddr) {
          tokens {
            media {
              visualAssets {
                sm {
                  staticUrl
                }
              }
            }
          }
        }
      }
    `,
		variables: { collectionAddr },
	})
}

const Collection = ({ collectionAddr, type }: CollectionProps) => {
	const [collectionData, setCollectionData] = useState<CollectionData | null>(null)
	const [tokensData, setTokensData] = useState<TokenData[]>([])
	const [error, setError] = useState<string | null>(null)
	//@ts-ignore
	const [loading, setLoading] = useState(true)

	const fetchData = useCallback(async () => {
		if (!collectionAddr) {
			setError('Collection address is missing')
			return
		}
		try {
			const { data: collectionResponse } = await getCollectionData(collectionAddr)
			setCollectionData(collectionResponse.collection)

			const { data: tokensResponse } = await getTokensData(collectionAddr)
			setTokensData(tokensResponse.tokens.tokens.slice(0, 6))
		} catch (err) {
			if (err instanceof Error) {
				setError(err.message || 'Error fetching collection data. Please try again later.')
			} else {
				setError('Error fetching collection data. Please try again later.')
			}
		} finally {
			setLoading(false)
		}
	}, [collectionAddr])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<div className={`collection ${type}`}>
			{!collectionData ? (
				<CollectionTheme />
			) : (
				<div className="collection-box">
					<div className="collection-wrapper">
						<div className="collection-heading">
							<div className="collection-heading-info">
								<h2>{collectionData.name}</h2>
								<p>{collectionData.description}</p>
							</div>
							<div className="collection-heading-img">
								<img
									src={collectionData.media.visualAssets.lg.staticUrl}
									alt={collectionData.name || 'Image name'}
								/>
							</div>
						</div>
						<div className="collection-info">
							<div className="collection-info-inner">
								<div className="collection-info-items">
									<div className="collection-info-items-left">
										<div className="collection-info-item">
											<span className="collection-info-item-name">Floor Price:</span>
											<span className="collection-info-item-num">
												{(collectionData.floorPrice / 1000000).toFixed(2)}
												<img src={stars} alt="" />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name">Highest Offer:</span>
											<span className="collection-info-item-num">
												{(collectionData.highestOffer?.offerPrice.amount ?? 0) / 1000000}
												<img src={stars} alt="" />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name">Royalties:</span>
											<span className="collection-info-item-num">
												{collectionData.royaltyInfo.sharePercent.toFixed(1) + '%'}
											</span>
										</div>
									</div>
									<div className="collection-info-items-right">
										<div className="collection-info-item">
											<span className="collection-info-item-name">Total Tokens:</span>
											<span className="collection-info-item-num">
												{collectionData.tokenCounts.active}
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name">Airdropped:</span>
											<span className="collection-info-item-num">
												{collectionData.tokenCounts.airdropped}
												<FaParachuteBox />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name">Burned:</span>
											<span className="collection-info-item-num">
												{collectionData.tokenCounts.burned}
												<FaFire />
											</span>
										</div>
									</div>
								</div>
								<a
									href={`https://www.stargaze.zone/m/${collectionData.contractUri}`}
									className="collection-info-link"
									target="_blank"
								>
									View on Stargaze
								</a>
							</div>
						</div>
					</div>
					<div className="collection-graph">
						<Graph collectionAddr={collectionAddr} />
					</div>
					<div className="collection-tokens">
						{tokensData.map((token, index) => (
							<ImageWithLoader
								key={index}
								src={token.media.visualAssets.sm.staticUrl}
								alt={`Token ${index + 1}`}
								spinnerSize="sm"
							/>
						))}
					</div>
					{error && <div className="collection-error">{error}</div>}
				</div>
			)}
		</div>
	)
}

export default Collection
