
import './Goals.scss'
import H2box from '../../../components/H2box/H2box'
import Goal from '../../../components/sections/Goal/Goal'
import { FaHandFist } from "react-icons/fa6"
import { IoPeopleSharp } from "react-icons/io5"
import { CiGift } from "react-icons/ci"
import { GiBroom } from "react-icons/gi"
import { IoIosCreate } from "react-icons/io"
import { RiCodeView } from "react-icons/ri"
import { MdAttractions } from "react-icons/md"
import { FaPeopleGroup } from "react-icons/fa6"

const Goals = () => {
	return (
		<section className='goals'>
			<div className="container">
				<div className="goals-wrapper">
					<H2box h2='Goals' subtitle='Our goal is to establish a thoughtfully planned strategy that we intend to follow to achieve our ambitious objectives.' margin='sm' />
					<div className="goals-inner">
						<div className="goals-left">
							<Goal icon={<FaHandFist />}
								title='Staking'
								description='Staking STARS to improve network security and value.'
							/>
							<Goal icon={<IoPeopleSharp />}
								title='Collaborations'
								description='Attracting and collaborations with communities from other networks to join Stargaze.'
							/>
							<Goal icon={<CiGift />}
								title='Bonuses'
								description='Bonuses for specific traits and for holders of our collections in total.'
							/>
							<Goal icon={<GiBroom />}
								title='Sweeping'
								description='Floor sweeping and burning to increase value of our collections.'
							/>
						</div>
						<div className="goals-right">
							<Goal icon={<IoIosCreate />}
								title='Creating'
								description='Creating cool art and doing something you love.'
							/>

							<Goal icon={<RiCodeView />}
								title='Development'
								description='Web3 development. Creating apps in connection with Stargaze.'
							/>
							<Goal icon={<MdAttractions />}
								title='Attracting'
								description='Attracting people from other fields to web3.'
							/>
							<Goal icon={<FaPeopleGroup />}
								title='Community'
								description='Being a part of best NFT community - Stargaze.'
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Goals