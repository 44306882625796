import './LinkArrow.scss'
import { FaArrowRight } from "react-icons/fa6";

interface LinkArrowProps {
	src: string,
	text: string
}

const LinkArrow = ({src,text} : LinkArrowProps) => {
	return (
		<a href={src} className="link-arrow" target="_blank">
			{text}
			<FaArrowRight />
		</a>
	)
}

export default LinkArrow