import { useEffect, useState } from 'react'
import './Header.scss'
import "@interchain-ui/react/styles"
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { toggleBodyScroll } from '../../../global/functions'
import { WINDOW_INNER_WIDTH_XL } from '../../../global/constants'
import WalletButton from "../../components/buttons/WalletButton/WalletButton"
import logo from '../../../assets/img/svg/logo.svg'

const Header = () => {
	const [isScrolled, setIsScrolled] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 0)
		}

		const handleResize = () => {
			if (window.innerWidth >= WINDOW_INNER_WIDTH_XL) {
				setIsMenuOpen(false)
				toggleBodyScroll(false)
			}
		}

		window.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const toggleMenu = () => {
		setIsMenuOpen((prev) => {
			const newState = !prev
			toggleBodyScroll(newState)
			return newState
		})
	}

	const closeMenu = () => {
		setIsMenuOpen(false)
		toggleBodyScroll(false)
	}

	return (
		<header className={`header ${isScrolled ? 'scrolled' : ''} ${isMenuOpen ? 'opened' : ''}`}>
			<div className="container">
				<div className="header-wrapper">
					<Link to="/" className='header-logo' onClick={closeMenu}>
						<img src={logo} alt="Cryptomatcha" loading="eager" />
					</Link>
					<div className="header-inner">
						<nav className='header-menu'>
							<ul className='header-list'>
								<li className='list-item'>
									<NavLink to="/apps" onClick={closeMenu}>
										Apps
									</NavLink>
								</li>
								<li className='list-item'>
									<NavLink to="/about" onClick={closeMenu}>
										About
									</NavLink>
								</li>
								<li className='list-item'>
									<NavLink to="/collections" onClick={closeMenu}>
										Collections
									</NavLink>
								</li>
								<li className='list-item'>
									<NavLink to="/partnerships" onClick={closeMenu}>
										Partnerships
									</NavLink>
								</li>
								<li className='list-item'>
									<NavLink to="/articles" onClick={closeMenu}>
										Articles
									</NavLink>
								</li>
							</ul>
						</nav>
						<WalletButton chainName="stargaze" />
					</div>
					<button className={`burger-button ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
						<span></span>
					</button>
				</div>
			</div>
		</header>
	)
}

export default Header