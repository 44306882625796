import { Outlet } from 'react-router-dom'
import Header from '../common/Header/Header'
import Footer from '../common/Footer/Footer'
import wave1 from '../../assets/img/svg/wave1.svg'
import wave2 from '../../assets/img/svg/wave2.svg'
import wave3 from '../../assets/img/svg/wave3.svg'

const MainLayout = () => {
	return (
		<div className="overlay">
			<div className='wrapper'>
				<Header />
				<main>
					<Outlet />
				</main>
				<Footer />
			</div>
			<img className='wave1' src={wave1} alt="" />
			<img className='wave2' src={wave2} alt="" />
			<img className='wave2' src={wave3} alt="" />
		</div>

	)
}

export default MainLayout
