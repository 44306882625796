import { Link } from "react-router-dom"
import './LinkRouter.scss'

interface LinkRouterProps {
	type: 'green' | 'gradient'
	src: string
	text: string
	targetBlank?: boolean
}

const LinkRouter = ({ type, src, targetBlank = false, text }: LinkRouterProps) => {
	return (
		<Link className={`link-router ${type}`} to={src} target={targetBlank ? "_blank" : "_self"} rel={targetBlank ? "noopener noreferrer" : undefined}>
			{text}
		</Link>
	)
}

export default LinkRouter