import './PartnershipsCard.scss'
import ImageWithLoader from "../../ImageWithLoader/ImageWithLoader"
import { RxDiscordLogo } from "react-icons/rx"
import { RiTwitterXLine } from "react-icons/ri"

interface PartnershipsCardProps {
	src: string
	discordLink: string
	xLink: string
	h4: string
	text: string
}

const PartnershipsCard = ({src, discordLink, xLink, h4, text}: PartnershipsCardProps) => {
	return (
		<div className="partnership-card">
			<div className="partnership-card-inner">
				<div className="partnership-card-top">
					<div className="partnership-card-img">
						<ImageWithLoader src={src} spinnerSize="md" />
					</div>
					<div className="partnership-card-additional">
						<div className="partnership-card-socials">
							<a href={discordLink} target='_blank'>
								<RxDiscordLogo />
							</a>
							<a href={xLink} target='_blank'>
								<RiTwitterXLine />
							</a>
						</div>
					</div>
				</div>
				<div className="partnership-card-bottom">
					<h4>
						{h4}
					</h4>
					<p>
						{text}
					</p>
				</div>
			</div>
			
		</div>
	)
}

export default PartnershipsCard