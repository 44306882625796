import { useMemo, memo, useState } from 'react'
import { TokenListProps } from '../types'
import './TokenList.scss'
import Pagination from '../Pagination/Pagination'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ImageWithLoader from '../../../components/ImageWithLoader/ImageWithLoader'

const TokenList = ({ tokenData, currentPage = 0, setCurrentPage }: TokenListProps) => {
    const [isLoading, setIsLoading] = useState(true)

    const displayedTokens = useMemo(() => {
        if (tokenData.tokens.length > 0) {
            setIsLoading(false)
        }
        return tokenData.tokens
    }, [tokenData.tokens])

    return (
        <div className="token-images">
            {isLoading ? (
                <div className="tokens-wrapper">
					<Skeleton baseColor='#222222' highlightColor='#33333350' count={4} height={200} />
                </div>
            ) : displayedTokens.length > 0 ? (
                <>
                    <div className="user-nfts">
                        {displayedTokens.map((token, index) => (
                            <div className="user-nft" key={index}>
								<ImageWithLoader src={token.media.visualAssets.xl.staticUrl} spinnerSize='sm' alt={`Token ${index + 1}`}  />
                                <div className="nft-score">
                                    Rarity: <span>{token.rarityOrder.toFixed(0)}</span>
                                </div>
                                <div className="nft-name">{token.name}</div>
                            </div>
                        ))}
                    </div>
                    {tokenData.total > 4 && setCurrentPage && (
                        <Pagination
                            currentPage={currentPage}
                            total={tokenData.total}
                            onPageChange={(direction) =>
                                setCurrentPage((prev) =>
                                    direction === 'next' ? prev + 1 : Math.max(prev - 1, 0)
                                )
                            }
                        />
                    )}
                </>
            ) : (
                null
            )}
        </div>
    )
}

export default memo(TokenList)
