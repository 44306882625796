import './Collaborations.scss'
import H2box from '../../../components/H2box/H2box'
import LinkRouter from '../../../components/links/LinkRouter/LinkRouter'
import InfoCard from '../../../components/Cards/InfoCard/InfoCard'
import c1 from '../../../../assets/img/sections/collaborations/c1.png'
import c2 from '../../../../assets/img/sections/collaborations/c2.png'
import c3 from '../../../../assets/img/sections/collaborations/c3.png'

const Collaborations = () => {
	const H2Props = {
		h2: 'Our collaborations',
		subtitle: 'We are proud to showcase our collaborations with amazing partners in the Stargaze community. Together, we are working on innovative projects that enhance user experiences and push the boundaries of creativity. Stay tuned to discover the exciting initiatives we’re launching with our friends at Stargaze!'
	}

	return (
		<section className='collaborations'>
			<div className="container">
				<div className="collaborations-wrapper">
					<H2box {...H2Props}  />
					<div className="collaborations-cards">
						<InfoCard  type='sm' glowId="c1" glowFill="#629CFA" iconSrc={c1}
						title='Shitmos' text='Shitmos Economic Zone - we are the one of first collections who joined SEZ. 20% of our both collections royalties goes to SEZ treasury for buybacks of Shitmos - a real community moving memecoin in whole Cosmos Community is very strong and grows every day.' />
						<InfoCard  type='sm' glowId="c" glowFill="#ffffff" iconSrc={c3}
						title='Dao Dao' text='Join Crypto Matcha DAO to improve our strength! Stake Hamsters and vote in governance. Earn plstaking points with multiplier!
						Revenues from treasury coming in future.' />
						<InfoCard  type='sm' glowId="c3" glowFill="#E8BA66" iconSrc={c2}
						title='The Smokers Club' text='We have many traits in both collections which gives you membership in Smokers Club - place where you can join rumbles and other games and win nice NFTs. Also you can soft-stake smokers NFT on plstaking to earn SMKR token and join raffles there.' />
					</div>
					<div className="link-wrapper row al-c jc-e">
						<LinkRouter type='green' src='/partnerships' text='View All' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Collaborations