import './AppCards.scss'
import AppCard from '../../../components/Cards/AppCard/AppCard'
import img from '../../../../assets/img/errors/s-error.png'

const AppCards = () => {
	return (
		<section className="app-cards">
			<div className="container">
				<div className="app-cards-wrapper">
					<AppCard type='disabled' src={img} name='Gif Merger(Console)'
						text='A simple tool to create unique GIF NFTS by merging multiple GIF layers, 
								with custom parameters including collection name, description, layer size and order,'
						linkSrc='#'
					/>
					<AppCard type='disabled' src={img} name='NFT builder'
						text='Powerful tool for creating and managing NFT collections. It supports layer-based generation, metadata customization, animated GIF creation, and rare token management. This builder simplifies the process, turning your ideas into unique digital assets with ease.'
						linkSrc='#'
					/>
				</div>
			</div>
		</section>
	)
}

export default AppCards