import './CollectionItems.scss'
import Collection from '../../../components/sections/Collection/Collection'

const CollectionItems = () => {
  return (
	<section className='collectionItems'>
		<div className="container">
			<div className="collectionItems-wrapper">
				<Collection collectionAddr='stars1k7lmdfx2eh5k3dt4jz3uuv4wl6s0tyft2twwjy0mgs3qxs3u3ynssv8dr2' type='' />
				<Collection collectionAddr='stars1u478mmpm4mv33dkhvt6eryrru3uer6lr5pp8vgt7mhyvxjekx9js9y3kvl' type='reverse' />
				<Collection collectionAddr='stars15nkxpzxhnhjxzwnteul2g77kxrn5y0n28p8ap3ymuznjys53zv5stgmf2d' type='' />
			</div>
		</div>
	</section>
)
}

export default CollectionItems