import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { API_BASE_URL } from '../../../global/constants'
import { getPostBySlug } from '../../../api/postApi'
import './ArticlesSingle.scss'
import DOMPurify from 'dompurify'
import Articles from '../../sections/Home/Articles/Articles'
import { MdDateRange } from 'react-icons/md'
import { IoMdTime } from 'react-icons/io'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TwitterShareButton } from 'react-share'
import { FaRegCopy } from 'react-icons/fa'
import { IoReturnUpBackSharp } from "react-icons/io5"
import { FaEye } from "react-icons/fa"
import { RiTwitterXLine } from "react-icons/ri"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ArticlesSingle = () => {
	const { category, slug } = useParams<{ category?: string; slug?: string }>()
	const [post, setPost] = useState<any | null>(null)
	const [copySuccess, setCopySuccess] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	useEffect(() => {
		if (category && slug) {
			fetchPost(category, slug)
		}
	}, [category, slug])

	const fetchPost = async (category: string, slug: string) => {
		setLoading(true)
		try {
			const response = await getPostBySlug(category, slug)
			setPost(response.data)
		} catch (error) {
			console.error('Error fetching post:', error)
		} finally {
			setLoading(false)
		}
	}

	if (loading) {
		return (
			<div className="sections">
				<section className="articles-single">
					<div className="container">
						<div className="articles-single-wrapper skeleton-wrapper">
							<div className="skeleton-wrapper-inner">
								<div className="skeleton-wrapper-inner-top">
									<div className="skeleton-wrapper-inner-left">
										<Skeleton baseColor='#222222' highlightColor='#33333350' height={60} width={60} />
									</div>
									<div className="skeleton-wrapper-inner-right">
										<Skeleton baseColor='#222222' highlightColor='#33333350' height={140} />
										<Skeleton baseColor='#222222' highlightColor='#33333350' height={40} />
									</div>
								</div>
								<div className="skeleton-wrapper-inner-bottom">
									<Skeleton baseColor='#222222' highlightColor='#33333350' height={40} count={2} />
									<Skeleton baseColor='#222222' highlightColor='#33333350' height={20} count={3} />
									<Skeleton baseColor='#222222' highlightColor='#33333350' height={300} />
								</div>
							</div>
						</div>
					</div>
				</section>
				<Articles />
			</div>
		)
	}

	const sanitizedContent = DOMPurify.sanitize(post.text)
	const postUrl = `${window.location.origin}/articles/${category}/${slug}`

	const handleCopy = () => {
		setCopySuccess('URL copied!')
		setTimeout(() => setCopySuccess(''), 2000)
	}

	const getReadingTimeText = (minutes: number) => {
		return minutes === 1 ? `${minutes} minute read` : `${minutes} minutes read`
	}

	const handleBackClick = () => {
		navigate(-1)
	}

	return (
		<div className="sections">
			<section className="articles-single">
				<div className="container">
					<div className="articles-single-wrapper">
						<div className="articles-single-top">
							<button className='back' onClick={handleBackClick}>
								<IoReturnUpBackSharp />
							</button>
							<div className="articles-single-top-info">
								<div className="articles-single-top-inner">
									<div className="articles-single-top-item">
										<IoMdTime /> <span>{getReadingTimeText(post.reading_time)}</span>
									</div>
									<div className="articles-single-top-item">
										<MdDateRange /> <span>{new Date(post.publication_date).toLocaleDateString()}</span>
									</div>
									<div className="articles-single-top-item">
										<FaEye /> <span>{post.views} views</span>
									</div>
								</div>
								<span className="category">Category: {post.category_name}</span>
							</div>
						</div>
						<div className="articles-single-content">
							<div className="articles-single-content-inner">
								<h1>{post.title}</h1>
								<div
									className="articles-single-text"
									dangerouslySetInnerHTML={{ __html: sanitizedContent }}
								/>
								<div className="articles-single-img">
									{post.image_path && (
										<img
											src={`${API_BASE_URL}${post.image_path}`}
											alt={post.title}
											className="articles-image"
										/>
									)}
								</div>
								<div className="articles-sharing">
									<p>
										You can retweet the article to share it with your followers or copy the link and send it directly to anyone who might find it interesting
									</p>
									<div className="sharing-buttons">
										<TwitterShareButton url={postUrl} title={post.title}>
											<RiTwitterXLine />
										</TwitterShareButton>
										<CopyToClipboard text={postUrl} onCopy={handleCopy}>
											<button className="copy-button">
												<FaRegCopy />
											</button>
										</CopyToClipboard>
										{copySuccess && <span className="copy-success">{copySuccess}</span>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Articles />
		</div>
	)
}

export default ArticlesSingle
