import { WalletInfoProps } from '../types'
import './WalletInfo.scss'
import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const WalletInfo = ({ walletStats }: WalletInfoProps) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (walletStats) {
            setLoading(false)
        }
    }, [walletStats])

    if (loading || !walletStats) {
        return (
			<div className="wallet-stats-skeleton">
				<Skeleton baseColor='#222222' highlightColor='#33333350' height={40} count={7} />
			</div>
		)
    }

    return (
        <div className="user-info">
            <div className="user-info-wrapper">
                <div className="user-info-item">
                    Buy Volume (USD): <span>${walletStats.buyVolumeUsd?.toFixed(0) || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Number of Buys: <span>{walletStats.numBuys || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Number of Mints: <span>{walletStats.numMints || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Number of Sells: <span>{walletStats.numSells || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Number of Trades: <span>{walletStats.numTrades || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Sell Volume (USD): <span>${walletStats.sellVolumeUsd?.toFixed(0) || 'N/A'}</span>
                </div>
                <div className="user-info-item">
                    Total Volume (USD): <span>${walletStats.totalVolumeUsd?.toFixed(0) || 'N/A'}</span>
                </div>
            </div>
        </div>
    )
}

export default WalletInfo
