import './InfoCard.scss'
import LinkArrow from '../../links/LinkArrow/LinkArrow'

interface InfoCardProps {
	type: string
	glowId: string
	glowFill: string
	iconSrc: string
	iconAlt?: string
	title: string
	text: string
	linkSrc?: string
	linkText?: string
}

const InfoCard = ({ type, glowId, glowFill, iconSrc, iconAlt, title, text, linkSrc, linkText }: InfoCardProps) => {

	return (
		<div className={`short-info-card ${type}`}>
			<div className="short-info-card-inner">
				<svg className="glow" width="380" height="182" viewBox="0 0 380 182" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g filter={`url(#${glowId})`}>
						<circle cx="190" cy="-18.5601" r="70" fill={glowFill}/>
					</g>
					<defs>
						<filter id={glowId} x="-10" y="-218.56" width="400" height="400" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix"/>
						<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
						<feGaussianBlur stdDeviation="65" result="effect1_foregroundBlur_55_275"/>
						</filter>
					</defs>
				</svg>
				<div className="short-info-card-title">
					{title}
				</div>
				<div className="short-info-card-text">
					<p>
						{text}
					</p>
					{linkText && linkSrc && (
						<LinkArrow src={linkSrc} text={linkText} />
					)}
				</div>
			</div>
			<div className="short-info-card-img">
				<img className="short-info-icon" src={iconSrc} alt={iconAlt} />
			</div>
		</div>
	)
}

export default InfoCard