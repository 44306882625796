import './CollectionTheme.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const CollectionTheme = () => {
	return (
		<div className="collection-skeleton">
			<SkeletonTheme baseColor='#222222' highlightColor='#33333350'>
				<div className="collection-box">
					<div className="collection-wrapper">
						<div className="collection-heading">
							<div className="collection-heading-info">
								<h2>
									<Skeleton />
								</h2>
								<p>
									<Skeleton count={3} />
								</p>
							</div>
							<div className="collection-heading-img">
								<Skeleton />
							</div>
						</div>
						<div className="collection-info">
							<div className="collection-info-inner">
								<div className="collection-info-items">
									<div className="collection-info-items-left">
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
									</div>
									<div className="collection-info-items-right">
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
										<div className="collection-info-item">
											<span className="collection-info-item-name"><Skeleton /></span>
											<span className="collection-info-item-num">
												<Skeleton />
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="collection-graph">
						<Skeleton />
					</div>
					<div className="collection-tokens">
						<Skeleton count={6} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	)
}

export default CollectionTheme