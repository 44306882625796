import Hero from "../../components/sections/Hero/Hero"
import WalletStats from "../../sections/UserDashboard/WalletStats/WalletStats"

const UserDashboard = () => {
	

	return (
		<div className="sections">
			<Hero type="center" h1="User dashboard" subtitle="View Your stats" />
			<WalletStats />
		</div>
	)
}

export default UserDashboard
