// Sections
import Hero from "../../components/sections/Hero/Hero"
import AppCards from "../../sections/Apps/AppCards/AppCards"
import Cta from "../../sections/Home/Cta/Cta"

const Apps = () => {
	return (
		<div className="sections">
			<Hero type="center" h1="Apps"
				subtitle="Bridging Technology and Creativity with Stargaze"
			/>
			<AppCards />
			<Cta />
		</div>
	)
}

export default Apps