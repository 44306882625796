import { useEffect, useState, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { TokenListProps } from '../types'
import './DaoList.scss'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

const DaoList = ({ tokenData, address, onTokenCountChange }: TokenListProps) => {
	const [stakedTokenIds, setStakedTokenIds] = useState<string[]>([])

	useEffect(() => {
		const fetchStakedTokens = async () => {
			try {
				const response = await fetch(
					`https://indexer.daodao.zone/stargaze-1/account/${address}/nft/stakedWithDaos`
				)
				const data = await response.json()
				const tokenIds = data[0]?.tokens || []
				setStakedTokenIds(tokenIds.map(String))
			} catch (error) {
				console.error('Error fetching staked tokens:', error)
			}
		}

		fetchStakedTokens()
	}, [address])

	const filteredTokens = useMemo(() => {
		return tokenData.tokens.filter(token =>
			stakedTokenIds.includes(String(token.tokenId))
		)
	}, [tokenData.tokens, stakedTokenIds])

	useEffect(() => {
		if (onTokenCountChange) {
			onTokenCountChange(filteredTokens.length)
		}
	}, [onTokenCountChange]) 

	return (
		<div className="token-images">
			{filteredTokens.length > 0 ? (
				<div className="dao-wrapper">
					<Swiper
						spaceBetween={24}
						slidesPerView={4}
						navigation={{
							nextEl: '.swiper-next',
							prevEl: '.swiper-prev',
						}}
						modules={[Navigation]}
						className="dao-swiper"
						breakpoints={{
							320: { slidesPerView: 1 },
							375: { slidesPerView: 2 },
							992: { slidesPerView: 3 },
							1366: { slidesPerView: 4 },
						}}
					>
						{filteredTokens.map((token, index) => (
							<SwiperSlide key={index}>
								<div className="user-nft">
									<img
										src={token.media.visualAssets.xl.staticUrl}
										alt={`Token ${index + 1}`}
									/>
									<div className="nft-score">
										Rarity: <span>{token.rarityOrder.toFixed(0)}</span>
									</div>
									<div className="nft-name">{token.name}</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
					<div className="swiper-buttons">
						<div className="swiper-prev">
							<FaArrowLeft />
						</div>
						<div className="swiper-next">
							<FaArrowRight />
						</div>
					</div>
				</div>
			) : (
				<p>
					Cryptomatcha NFTs are not stacked in the DAO. To stake visit this link{' '}
					<a
						href="https://daodao.zone/dao/stars1d9qpjn8nr8fses9k7w0yhtyfu0fw9jm00xz27hz7nmcfqan7srkqk42k28/home"
						rel="noreferrer"
					>
						https://daodao.zone/dao/
					</a>
				</p>
			)}
		</div>
	)
}

export default DaoList
