// Sections
import Hero from "../../components/sections/Hero/Hero"
import Team from "../../sections/About/Team/Team"
import Goals from "../../sections/About/Goals/Goals"
import Articles from "../../sections/Home/Articles/Articles"

const About = () => {
	return (
		<div className="sections">
			<Hero type="center" h1="About Us"
				subtitle="In these times, when the world of digital assets and technology is  evolving at a surprisingly fast pace, young and ambitious teams play a  key role in shaping this trend."
			/>
			<Team />
			<Goals />
			<Articles />
		</div>
	)
}

export default About