import './H2box.scss'

interface H2Props {
	h2: string
	subtitle: string
	margin?: string
}

const H2box = ({ h2, subtitle, margin }: H2Props) => {
	return (
		<div className={`h2-box ${margin}`}>
			<h2>
				{h2}
			</h2>
			<div className="h2-subtitle">
				{subtitle}
			</div>
		</div>
	)
}

export default H2box
